import React, { useContext, useEffect, useState, useTransition } from "react";
import useSWR from 'swr'
import Heading from "../components/Heading";
import config from "../lib/config";
import dynamic from "next/dynamic";
import AppContext from "../components/AppContext";
import userModel from "../models/user.model";
import helper from "../lib/helper";
import Link from "next/link";
import _ from "lodash";
import { useSession } from "next-auth/react";
const Wallet=dynamic(() => import('../components/Wallet'), {
    loading: () => <p>Loading...</p>,
  })
const Position=dynamic(() => import('../components/Position'), {
    loading: () => <p>Loading...</p>,
  })
const History=dynamic(() => import('../components/History'), {
    loading: () => <p>Loading...</p>,
  })
const Page = function () {
const {data:userData,session}=useSession();
const [totalAmounts,setTotalAmount]=useState(0);
    const themeContext = useContext(AppContext);
    const { data: walletData, mutate: reloadordersData, isLoading } = useSWR(
        "order-widgets",
        () => userModel.list([]),
        config.swrConfig
    );

    useEffect(()=>{
        setTimeout(function(){
            setTotalAmount(_.sum(_.filter(themeContext?.totalAmounts,_.isNumber)))    
        },3000)
    },[]);

    return (
        <>
            <Heading title="Dashboard" />
            <div className="card">
            <div className="card-header">
                Total Amount : 
                <span className="float-end">{totalAmounts?helper.currency.rupees(totalAmounts):"Loading..."}</span>
            </div>
        </div>
            <div className="">
            {userData?.user?.name=="admin" ?<>
                            {walletData?.data.map((user)=>{
                                return (
                 <Wallet user={user}/>
                            );
                        })}
                      
                </>
                :
                <>
                 <Wallet user={userData?.user}/>
               <hr/>
               <Position/>
               <hr/>
               <History/>
                </>}
            </div>
            
              
        </>
    );
};

Page.auth=true;
export default Page;
