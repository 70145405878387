import axiosInstance from "../lib/axiosInstance";

class userModel {
  constructor() {
    this.base_url = "/users";
  }
  async list(query = []) {
    return (
      await axiosInstance.get(`${this.base_url}?${new URLSearchParams(query)}`)
    ).data;
  }
  async balance(query = []) {
    return (
      await axiosInstance.get(`${this.base_url}/balance?${new URLSearchParams(query)}`)
    ).data;
  }

  async create(data = []) {
    return await axiosInstance.post(this.base_url, data);
  }
  async update(id, data = []) {
    return await axiosInstance.post(`${this.base_url}/${id}?_method=PUT`, data);
  }
  async destroy(id, data = []) {
    return await axiosInstance.delete(`${this.base_url}/${id}`, data);
  }
  async show(id) {
    return (await axiosInstance.get(`${this.base_url}/${id}`)).data;
}
  async setting(id, data = []) {
    return await axiosInstance.post(`${this.base_url}/${id}/setting`,data);
}
  async getSetting(id) {
    return (await axiosInstance.get(`${this.base_url}/${id}/setting`)).data;
}

}

export default new userModel();
