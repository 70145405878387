import { Spinner } from "react-bootstrap";
export default function Heading({ title = [], showLoader = false, children }) {
  
  return (
    <div className="card border-0 mt-1">
      <div className=" card-header bg-transparent d-flex py-2 align-content-center  justify-content-between">
        <div className="h5">{title}</div>
      </div>
      {showLoader && <div className="bg-loader"><Spinner size="lg" title="Loading..." /></div>}
      
    </div>
  )
}